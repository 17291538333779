import styles from "./design-system.module.css";
import "./design-system.css"
import Circle from "./circle.png";
import "./testing.css"


const Testing = () => {
  return (
    <div className="testing">
      <div className="testing-inner">
        <div className="buttons">
          <button className={styles.buttonSecondary}>Buy Now</button>
          <button className={styles.button}>Connect</button>
          <button className={styles.buttonSmallSecond}>+</button>
          <button className={styles.buttonSmall}>-</button>
        </div>

        <div className="icons">
          <img src={Circle} className={styles.ExtraLargeIcon}></img>
          <img src={Circle} className={styles.largeIcon}></img>
          <img src={Circle} className={styles.mediumIcon}></img>
          <img src={Circle} className={styles.smallIcon}></img>
        </div>

        <div className="headings">
          <h1 className={styles.heading}>Header lorem ipsum dolor</h1>
          <h2 className={styles.subheading}>Subheader lorem ipsum dolor sit</h2>
          <p className={styles.text}>
            Text lorem ipsum dolor sit amet, consectetur adipiscing elit
          </p>
        </div>

        <div className="colors">
          <h1 style={{ color: "var(--color-primary)" , fontFamily: "var(--font-primary)"}}>color primary</h1>
          <h1 style={{ color: "var(--color-secondary)", fontFamily: "var(--font-primary)" }}>color secondary</h1>
          <h1 style={{ color: "var(--color-main-text)", fontFamily: "var(--font-primary)" }}>color main text</h1>
          <h1 style={{ color: "var(--color-secondary-text)", fontFamily: "var(--font-primary)" }}>
            color secondary text
          </h1>
          <h1 style={{ color: "var(--color-success)", fontFamily: "var(--font-primary)" }}>color success</h1>
          <h1 style={{ color: "var(--color-warning)", fontFamily: "var(--font-primary)" }}>color warning</h1>
          <h1 style={{ color: "var(--color-danger)", fontFamily: "var(--font-primary)" }}>color danger</h1>
          <h1 style={{ color: "var(--color-info)", fontFamily: "var(--font-primary)" }}>color info</h1>
          <h1 style={{ color: "var(--color-white)", fontFamily: "var(--font-primary)" }}>color white</h1>
        </div>
      </div>
    </div>
  );
};

export default Testing;
